import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IHighLightProps {
  title: string;
  link: string;
  author?: string;
}

function HighLightCard({ title, link, author }: IHighLightProps) {
  return (
    <a className="card" href={link}>
      <div>
        <FontAwesomeIcon icon={"scroll"} />
      </div>
      <div>
        <b>{title}</b>
        <br />
        <b>By : </b>
        <em>{author}</em>
      </div>
    </a>
  );
}

export default HighLightCard;
