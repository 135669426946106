import React from "react";
import "../styles/HomePage.scss";

import homeimage from "../images/Report-amico.png";
import HighLightCard from "../components/HighLightCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HomePage() {
  return (
    <div className="home">
      <div className="presentation">
        <div className="hero-header">
          <h1>Welcome on KerNel Papers</h1>
          <h2>Scientific papers for clinical research</h2>
        </div>
        <a href="/papers" className="mainbutton">
          Search for a paper
          <FontAwesomeIcon icon={"arrow-alt-circle-right"} />
        </a>
        <div className="highlights">
          <h3>
            <FontAwesomeIcon icon={"thumbs-up"} />
            Highlighted paper
          </h3>
          <HighLightCard
            title="Addition of bacterial filter alters positive airway pressure and
        non-invasive ventilation performances"
            // link="./article/bench-phillips"
            link="https://erj.ersjournals.com/content/early/2022/01/20/13993003.02636-2021"
            author="Rabec C, Fresnel E, Rétory Y, et al."
          />
        </div>
      </div>
      <img src={homeimage} />
    </div>
  );
}

export default HomePage;
